import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'

Vue.use(MLInstaller)

export default new MLCreate({
    initial: 'en',
    save: process.env.NODE_ENV === 'production',
    languages: [
        new MLanguage('en').create({
            Home:'HOME',
            About: 'ABOUT',
            Statistic: 'STATISTIC',
            Account: 'ACCOUNT',
            //-------main page---------
            slogan1: 'Service for investing in the real economy',
            slogan2: 'Money should work! Only in this case they will not be lost.',
            more: 'DISCOVER MORE',
            start: 'START INVESTING',
            or: 'or',
            advantages: 'advantages',
            advantages_text: 'A modern approach to investing allows you to approach funds management at a qualitatively new level. The ability to quickly invest and receive high, stable profitability, while maintaining complete confidentiality of your personal data.',

            reliability: 'reliability',
            privacy: 'privacy',
            stability: 'stability',
            professionalism: 'professionalism',
            convenience: 'convenience',
            support: 'support',
            reliability_text: 'Modern technologies make it possible to achieve a reliable and fault-tolerant solution',
            privacy_text: 'Confidential data exchange between authorized participants',
            stability_text: 'Stable and high income due to diversification and high-quality selection of areas for investment',
            professionalism_text: 'Many years of experience in the field of investment and technology allows us to always stay in trend and take into account the accumulated experience',
            convenience_text: 'Modern tools for analyzing and managing your investments',
            support_text: 'Support at all stages of investment',

            have_any_questions: 'HAVE ANY QUESTIONS? LET US KNOW!',
            have_any_questions_text: 'Send any questions and suggestions on partnership to',
            send_to_support: 'write to support',
            subscribe_to_channel: 'follow us',
            subscribe_to_channel_motive: 'Subscribe to our Telegram channel in order not to miss announcements and useful notifications!',

            stay_with_us: 'STAY WITH US',
            ask_us_your_question: 'ASK US YOUR QUESTION',

            your_opinion: 'Your opinion is very important to us, and we will be grateful for any feedback, because we are constantly improving and want to be in constant dialogue with our partners, because this is the only way we can reach new heights.',
            send: 'send',

            subscribe: 'subscribe',
            subscribe_header: 'SUBSCRIBE TO OUR NEWSLETTER',
            your_request: 'Your request and description',
            function_compare: 'COMPARISON OF FEATURES',

            convenient_access: 'Convenient access',
            convenient_access_text: 'From any platform - desktop, tablet or mobile phone - you are always aware of what is happening with your money.',
            detailed_statistics: 'Detailed statistics',
            detailed_statistics_text: 'We try to provide the most detailed information on investments and dividend payments in an easy-to-understand form.',
            data_protection: 'Data protection',
            data_protection_text: 'All information is securely stored in a distributed network, which excludes the possibility of errors, fake and unverified transactions.',
            support247: '24/7 Support',
            support247_text: 'We will always answer any questions related to your investment and the work of the service.',

            profit: 'Annual percentage of profit',
            removal_possibility: 'Removal possibility',
            support_all_stages: 'Support at all stages',
            compilance: 'Compliance with Russian legislation',
            anonimity: 'Anonymity of personal data',

            become_owner: 'Become a SYRF Coin owner and take your investment approach to the next level.',
            bank_depo: 'Bank deposit',
            //---------statistic------------
            div_history: 'Dividend payouts history',
            emit: 'Issued',
            fired: 'Fired',
            paid_in: 'Paid in',
            profitability: 'Profitability (% per month)',
            details: 'details',
            emit_date: 'Date of issue:',
            amount_coins: 'Amount of coins:',
            coin_holders: 'сoin holders',
            amount_coins_in_use:'Amount of coins in circulation:',
            annual_return:'Average annual return:',
            total_payout:'Total payout:',
            ident: 'Token ID:',
            description: 'Description:',
            price_in_market: 'Exchange price (sale / purchase):',
            distribution_scheme: 'profit distribution scheme',
            all_coins_distributed_as: 'All funds received are divided as follows:',
            scheme1: '10% is poured into the order book and placed on a buyout at the price of 0.9 USDT',
            scheme2: '30% return on investment and development of the token, an increase in the funds received',
            scheme3: '60% are distributed in proportion to the shares on the wallets of SYRF token holders',
            resume: 'Dividend payments are made twice - from the 1st to the 3rd and from the 15th to the 18th of the month.',

            payout_date: 'Payment date',
            transaction_id: 'Transaction ID',
            comment: 'Comment',
            coin: 'Coin',
            amount: 'Amount',
            course: 'Token rate (in USDT)',
            in_usdt: 'in USDT',

            easy_to_invest: 'IT IS EASY TO INVEST IN SYRF',
            anonim_reg: 'Anonymous registration',
            useful: 'Convenient to use',
            comfort_payouts: 'Comfortable payments',
            create_acc_waves: 'CREATE WAVES ACCOUNT',
            buy_in_market: 'BUY SYRF ON EXCHANGE',
            get_stable_profit: 'GET STABLE INCOME',
            create_acc_waves_text: 'This will take less than a minute and you do not need to provide your personal information to register the wallet.',
            buy_in_market_text: 'By funding your wallet, you will be able to buy SYRF token on waves.exchange in SYRF/USDT pair. This will be similar to direct investment in the real sector of the economy with a high interest rate. ',
            get_stable_profit_text: 'Dividend payments are made to your wallet at least twice a month in SYRF, USDT, ETH or BTC tokens.',
            // --------- account ----------
            private_territory: 'You are in a private territory!',
            private_territory_text: 'To access the investors personal reports, please log in to the Waves Keeper plugin and grant permission to the site to recognize you. Alternatively, you can simply enter your wallet address and access detailed summaries of your account. ',
            auth_waves_keeper: 'LOG IN WITH WAVES KEEPER',
            auth_wallet_number: 'LOG IN WITH WALLET INPUT',
            please_input_address: 'Please enter your WAVES wallet address',
            all_tokens: 'All tokens',
            in_circulation: 'In circulation',
            on_your_wallet: 'On your wallet',
            direction_investment: 'Investment directions',
            it: 'IT',
            real_estate: 'Real estate',
            mechanical_engineering: 'Mechanical engineering',
            agriculture: 'Agriculture',
            it_desc: 'Today, IT technologies are tightly intertwined with life and business. New projects and companies are born every day, not to mention the already existing IT giants. Investing in these technologies is becoming more relevant and more profitable. But! You need to understand that this area also carries high risks. Through careful selection, we diversify (distribute) investments in this direction, which gives higher guarantees for further profit.',
            real_estate_desc: 'This is a long-term investment, which is considered one of the stable investment options. It makes it possible to both increase capital, ensure stable profits, and reduce the impact of many external factors, including inflation.',
            mechanical_engineering_desc: 'One of the important indicators of any state is the progress of the scientific and technical level of development and achievements in the field of mechanical engineering. Today, machines of various kinds are helping humanity more and more and are becoming smarter, and this entails more and more interest in this direction and its development.',
            agriculture_desc: 'Agriculture is an industry that ensures the national security of any state in the world, reducing dependence on other states. The correct approach to doing business in this direction gives a high profitability even when long-term investment is taken into account. Why agriculture? Because even the most advanced technologies will not replace food for you!',
            what_is_syrf_header:'What is the SYRF?',

        }),

        new MLanguage('ru').create({
            Home: 'ГЛАВНАЯ',
            About: 'О НАС',
            Statistic: 'СТАТИСТИКА',
            Account: 'КАБИНЕТ',
            //-------main page---------
            slogan1: 'Сервис для инвестирования в реальную экономику',
            slogan2: 'Деньги должны работать! Только в этом случае они не пропадут',
            more: 'УЗНАТЬ БОЛЬШЕ',
            start: 'Начать инвестировать',
            or: 'или',
            advantages: 'преимущества',
            advantages_text: 'Современный подход к инвестированию позволяет на качественно новом уровне подойти к управлению средствами. Возможность оперативно инвестировать и получать высокую, стабильную доходность, при этом сохраняя полную конфиденциальность ваших персональных данных.',

            reliability: 'надежность',
            privacy: 'приватность',
            stability: 'стабильность',
            professionalism: 'профессионализм',
            convenience: 'удобство',
            support: 'поддержка',
            reliability_text: 'Современные технологии позволяют добиться надежного и отказоустойчивого решения',
            privacy_text: 'Конфиденциальный обмен данными между авторизованными участниками',
            stability_text: 'Стабильный и высокий доход за счет диверсификации и качественного отбора направлений для инвестирования',
            professionalism_text: 'Многолетний опыт работы в сфере инвестиций и технологий позволяют всегда оставаться в тренде и учитывать накопленный опыт',
            convenience_text: 'Современные инструменты для анализа и управления вашими инвестициями',
            support_text: 'Поддержка и сопровождение на всех этапах инвестирования',

            have_any_questions: 'ПОЯВИЛИСЬ ВОПРОСЫ? ДАЙТЕ НАМ ЗНАТЬ!',
            have_any_questions_text: 'Любые вопросы и предложения по партнерству направляйте по адресу',
            send_to_support: 'написать в поддержку',

            subscribe_to_channel: 'подписаться на канал',
            subscribe_to_channel_motive: 'Подпишитесь на наш канал Telegram чтобы не пропускать анонсы и полезные уведомления!',

            stay_with_us: 'ОСТАВАЙТЕСЬ С НАМИ НА СВЯЗИ',
            ask_us_your_question: 'ЗАДАЙТЕ НАМ СВОЙ ВОПРОС',

            your_opinion: 'Нам очень важно Ваше мнение и мы будем благодарны за любую обратную связь, т.к. мы постоянно совершенствуемся и хотим находиться в постоянном диалоге с нашими партнерами, ведь только так мы сможем достигнуть новых высот.',
            send: 'отправить',
            subscribe_header: 'ПОДПИСАТЬСЯ НА НАШУ РАССЫЛКУ',
            subscribe: 'подписаться',

            your_request: 'Ваш запрос и описание',
            function_compare: 'Сравнение особенностей',

            convenient_access: 'Удобный доступ',
            convenient_access_text: 'С любой платформы - настольный ПК, планшет или мобильный телефон - вы всегда в курсе что происходит с вашими деньгами.',
            detailed_statistics: 'Подробная статистика',
            detailed_statistics_text: 'Мы стараемся предоставить максимально подробную информацию по инвестициям и выплатам дивидендов в удобном для восприятия виде.',
            data_protection: 'Защита данных',
            data_protection_text: 'Вся информация надежно хранится в распределенной сети, что исключает возможность ошибок, поддельных и непроверенных сделок.',
            support247: '24/7 Поддержка',
            support247_text: 'Мы всегда ответим на любые вопросы, связанные с вашими инвестициями и работой сервиса.',


            profit: 'Годовой процент прибыли',
            removal_possibility: 'Возможность снятия',
            support_all_stages: 'Поддержка на всех этапах',
            compilance: 'Соответствие законодательству РФ',
            anonimity: 'Анонимность персональных данных',

            become_owner: 'Станьте владельцем монет SYRF и поднимите свой подход к инвестированию на качественно новый уровень.',
            bank_depo: 'Банковский депозит',

            //---------statistic------------
            div_history: 'История выплат дивидендов',
            emit: 'Эмитировано',
            fired: 'Сожжено',
            paid_in: 'Выплачено в',
            profitability: 'Доходность (% в месяц)',
            details: 'подробнее',
            emit_date: 'Дата выпуска:',
            amount_coins: 'Количество монет:',
            coin_holders: 'держатели монет',
            amount_coins_in_use:'Количество монет в обороте:',
            annual_return:'Доходность за последние 12 месяцев:',
            total_payout:'Всего выплачено:',
            ident: 'Идентификатор:',
            description: 'Описание:',
            price_in_market: 'Цена на бирже (продажа/покупка):',
            distribution_scheme: 'схема распределения прибыли',
            all_coins_distributed_as: 'Все полученные средства делятся так:',
            scheme1: '10% переливаются в торговый стакан и ставятся на откуп по цене 0,9 USDT',
            scheme2: '30% возврат в инвестирование и развитие токена, увеличение полученных средств',
            scheme3: '60% распределяются соразмерно долям на кошельках держателей токена SYRF',
            resume: 'Выплаты дивидендов осуществляются дважды - с 1 по 3 и с 15 по 18 число ежемесячно.',


            payout_date: 'Дата выплаты',
            transaction_id: 'ID транзакции',
            comment: 'Комментарий',
            coin: 'Монета',
            amount: 'Количество',
            course: 'Курс токена (в USDT)',
            in_usdt: 'Сумма в USDT',

            easy_to_invest: 'ИНВЕСТИРОВАТЬ В SYRF ЛЕГКО',
            anonim_reg: 'Анонимная регистрация',
            useful: 'Удобно использовать',
            comfort_payouts: 'Комфортные выплаты',
            create_acc_waves: 'СОЗДАЙТЕ АККАУНТ WAVES',
            buy_in_market: 'КУПИТЕ SYRF НА БИРЖЕ',
            get_stable_profit: 'ПОЛУЧАЙТЕ СТАБИЛЬНЫЙ ДОХОД',
            create_acc_waves_text: 'Это займет меньше минуты, и для регистрации кошелька вам не потребуется предоставлять свои персональные данные.',
            buy_in_market_text: 'Пополнив свой кошелек, вы сможете купить токен SYRF на бирже waves.exchange в паре SYRF/USDT. Это будет аналогично прямому инвестированию в реальный сектор экономики с высокой процентной доходностью.',
            get_stable_profit_text: 'Выплаты дивидендов производятся на ваш кошелек не реже двух раз в месяц в токенах SYRF, USDT, ETH или BTC.',

            //---------account----------
            private_territory: 'Вы находитесь на приватной территории!',
            private_territory_text: 'Для доступа к персональным отчетам инвестора, пожалуйста, пройдите авторизацию в плагине Waves Keeper и предоставьте разрешение сайту узнать вас. Либо вы можете просто указать свой адрес кошелька и получить доступ к подробным сводкам по вашему счету.',
            auth_waves_keeper: 'АВТОРИЗОВАТЬСЯ ЧЕРЕЗ WAVES KEEPER',
            auth_wallet_number: 'АВТОРИЗОВАТЬСЯ ЧЕРЕЗ ВВОД КОШЕЛЬКА',
            please_input_address: 'Пожалуйста, введите адрес WAVES кошелька',
            all_tokens: 'Всего токенов',
            in_circulation: 'В обороте',
            on_your_wallet: 'На вашем кошельке',
            direction_investment: 'Направления инвестирования',
            it: 'IT',
            real_estate: 'Недвижимость',
            mechanical_engineering: 'Машиностроение',
            agriculture: 'Сельское хозяйство',
            it_desc: 'Сегодня IT технологии плотно переплелись с жизнью и бизнесом. Ежедневно рождаются новые проекты и компании, не говоря про уже существующих IT гигантов. Инвестировать в данные технологии становится все более актуальным и более прибыльным. Но! Нужно понимать, что данное направление несет так же и высокие риски. Путем тщательного отбора мы диверсифицируем (распределяем) инвестиции в данном направлении, что дает более высокие гарантии в дальнейшем получении прибыли.',
            real_estate_desc: 'Это долгосрочное инвестирование, которое считается одним из стабильных вариантов вложения. Дает возможность как приумножить капитал, обеспечить стабильную прибыль, так и снизить воздействие многих внешних факторов включая инфляцию.',
            mechanical_engineering_desc: 'Одним из важных показателей любого государства – это прогресс научно-технического уровня развитости и достижений в области машиностроения. На сегодняшний день машины разного рода все больше помогают человечеству и становятся все умнее, а это влечет за собой все более и более высокий интерес к данному направлению и его развитию.',
            agriculture_desc: 'Сельское хозяйство — это отрасль, обеспечивающая национальную безопасность любого государства мира, снижая зависимость от других государств. Правильный подход к ведению деятельности в данном направлении дает высокую доходность даже при учете долгосрочного инвестирования. Почему сельское хозяйство? Потому, что даже самые передовые технологии не заменят вам продуктов питания!',
            what_is_syrf_header:'Что такое SYRF?',

        })
    ]
})
