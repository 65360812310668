<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card :title="title" pace="6">
        {{$ml.get('your_opinion')}}
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseBusinessInfo",

  props: { dark: Boolean, title: String },

  data: () => ({
    
  }),
};
</script>
