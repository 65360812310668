<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />
    <v-form ref="form" lazy-validation v-model="formvalid">
      <base-text-field
        label="Name"
        v-model="name"
        :rules="namerules"
        :counter="30"
        required
      />

      <base-text-field
        label="Email"
        v-model="email"
        type="email"
        :rules="mailrules"
        :counter="40"
        required
      />

      <base-text-field label="Theme" v-model="subject" :counter="40" :rules="themerules"/>

      <base-textarea
        class="mb-6"
        :label="$ml.get('your_request')"
        v-model="text"
        :counter="350"
        :rules="textrules"
      />

      <base-btn
        :color="!theme.isDark ? 'accent' : 'white'"
        v-on:click="sendRequest()"
        outlined
        target="_blank"
      >
        {{$ml.get('send')}}
      </base-btn>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
var serverUrl = "https://syrf.info";
export default {
  name: "BaseContactForm",

  // Injected from the Vuetify Themeable mixin
  inject: ["theme"],

  props: {
    subtitle: String,
    title: {
      type: String,
      default: "MAIL US YOUR MESSAGE",
    },
  },
  data: () => ({
    name: "",
    email: "",
    subject: "",
    text: "",
    formvalid: true,
    namerules: [
      (value) => !!value || "Required.",
      //value=>value
      (value) => (value || "").length <= 30 || "Max 30 characters",
      (value) => {
        const pattern = /^([a-zA-Zа-яА-Я\- _0-9]*)$/;
        return pattern.test(value) || value == null || "Invalid string."; //если не пустое поле и не соотв паттерну, то ошибка
      },
    ],
    mailrules: [
      //правила для валидации инпутов
      (value) => !!value || "Required.",
      (value) => (value || "").length <= 40 || "Max 40 characters",
      (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    ],
    themerules: [
      //правила для валидации инпутов
      (value) => !!value || "Required.",
      (value) => (value || "").length <= 40 || "Max 40 characters",
      (value) => {
        const pattern = /^([a-zA-Zа-яА-Я\- _0-9\r\n,.?!]*)$/;
        return pattern.test(value) || "Invalid text.";
      },
    ],
    textrules: [
      //правила для валидации инпутов
      (value) => !!value || "Required.",
      (value) => (value || "").length <= 350 || "Max 350 characters",
      (value) => {
        const pattern = /^([a-zA-Zа-яА-Я\- _0-9\r\n,.?!]*)$/;
        return pattern.test(value) || "Invalid text.";
      },
    ],
  }),
  methods: {
    sendRequest() {
      //this.$refs.form.resetValidation();
      if (!this.$refs.form.validate()) return;
      var message = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        text: this.text,
      };
      console.log(message);
      const headers = { "Content-Type": "application/json", charset: "UTF-8" };
      axios
        .post(serverUrl + "/api/SendMessage", message, { headers })
        .then((response) => {
          alert("Ваше сообщение успешно отправлено");
        });
    },
  },
};
</script>
